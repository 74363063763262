import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';

import { inject, Injectable } from '@angular/core';

import { ScenarioDetailQuery } from '../scenario-detail';
import { ResBidActivationStateI } from './res-bid-activation.state';
import { ResBidActivationStore } from './res-bid-activation.store';

@Injectable({ providedIn: 'root' })
export class ResBidActivationQuery extends ScenarioDetailQuery<ResBidActivationStateI> {
  constructor() {
    const keeper = inject(ActiveKeeperService);
    const store = inject(ResBidActivationStore);
    super(store, keeper);
  }
}
