import { ReservesCompletion } from 'prosumer-app/+scenario/services/completion-strategies/reserves.strategy';
import { ReservesRefBuilderService } from 'prosumer-app/+scenario/services/reserves-ref-builder';
import {
  ScenarioCompletionService,
  ScenarioWizardStep,
} from 'prosumer-app/+scenario/services/scenario-completion';
import { StepFormComponent } from 'prosumer-app/libs/eyes-shared/components/step-form';
import { EyesSharedModule } from 'prosumer-app/libs/eyes-shared/eyes-shared.module';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  inject,
  input,
  OnInit,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NgControl, UntypedFormBuilder } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ResBidActivationTableComponent } from '../res-bid-activation-table';
import { ReservesDefinitionTableComponent } from '../reserves-definition-table';

@UntilDestroy()
@Component({
  selector: 'prosumer-reserves-form',
  templateUrl: './reserves-form.component.html',
  styleUrls: ['./reserves-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    EyesSharedModule,
    ReservesDefinitionTableComponent,
    ResBidActivationTableComponent,
  ],
})
export class ReservesFormComponent extends StepFormComponent implements OnInit {
  private refBuilder = inject(ReservesRefBuilderService);

  isViewOnly = input<boolean>();
  isMultiNode = input<boolean>();
  isViewMode = computed(() => this.isViewOnly() || this.mode === 'read');

  refsReserves = toSignal(this.refBuilder.selectReserveRefs());

  constructor(
    public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    public formBuilder: UntypedFormBuilder,
    private completion: ScenarioCompletionService,
  ) {
    super(ngControl, changeDetector, formBuilder);
    this.subscribeToFormForCompletionTracking();
  }

  defineForm() {
    return {};
  }

  canNotAdd = () => this.isViewOnly || this.mode === 'read';
  canNotEdit = () => this.isViewOnly || this.mode === 'read';
  canNotDelete = () => this.isViewOnly || this.mode === 'read';
  canNotView = () => !this.isViewOnly && this.mode !== 'read';

  private subscribeToFormForCompletionTracking(): void {
    const strat = new ReservesCompletion();
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((form) => {
      this.completion.setForStep(
        ScenarioWizardStep.reserves,
        strat.determineStatus(form),
      );
    });
  }
}
