import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';

import { inject, Injectable } from '@angular/core';

import { ScenarioDetailQuery } from '../scenario-detail';
import { ReserveDefinitionStateI } from './reserve-definition.state';
import { ReserveDefinitionStore } from './reserve-definition.store';

@Injectable({ providedIn: 'root' })
export class ReserveDefinitionQuery extends ScenarioDetailQuery<ReserveDefinitionStateI> {
  constructor() {
    const keeper = inject(ActiveKeeperService);
    const store = inject(ReserveDefinitionStore);
    super(store, keeper);
  }
}
