import {
  InputTypeI,
  TimeBlockDataType,
} from '../../components/time-blocks-yearly-input';

export class TimeBlockUtilService {
  static extractTimeBlockIntervalToExcelDt(tb: TimeBlockDataType): string {
    return this.extractHeader(tb[0]) + '\n' + this.extractBody(tb);
  }

  private static extractHeader(interv: Record<string, string>): string {
    return Object.keys(interv).join('\t');
  }

  private static extractBody(tb: TimeBlockDataType): string {
    return tb.map((e) => Object.values(e).join('\t ')).join('\n');
  }

  static transformPasteddData(
    data: string,
    colType: Record<string, InputTypeI>,
  ) {
    const rows = data.trim().split('\n');
    const headers = rows[0].split('\t').map((header) => header.trim());
    const result = [];
    for (let i = 1; i < rows.length; i++) {
      const columns = rows[i].split('\t').map((col) => col.trim());
      const obj = {};
      headers.forEach(
        (header, index) =>
          (obj[header] =
            colType[header] === 'checkbox'
              ? !this.isFalsy(columns[index])
              : columns[index]),
      );
      result.push(obj);
    }
    return result;
  }

  private static isFalsy(v) {
    return [
      null,
      undefined,
      '0',
      0,
      false,
      'false',
      'False',
      'FALSE',
      '',
    ].includes(v);
  }
}
