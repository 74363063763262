<div class="p-2 flex gap-2">
  @if (animateCopyBtn()) {
    <button
      mat-flat-button
      color="primary"
      [disabled]="true"
      data-testid="timeBlockYearly-input-btn-copied"
    >
      Data Copied
    </button>
  } @else {
    <button
      mat-flat-button
      color="primary"
      [disabled]="isLoading()"
      (click)="onCopy()"
      data-testid="timeBlockYearly-input-btn-copy"
    >
      Copy Data
    </button>
  }
  @if (animatePasteBtn()) {
    <button
      mat-flat-button
      color="primary"
      [disabled]="true"
      data-testid="timeBlockYearly-input-btn-pasted"
    >
      Pasting Data
    </button>
  } @else {
    <button
      mat-flat-button
      color="warm"
      [disabled]="isViewOnly() || isLoading()"
      (click)="onPaste()"
      data-testid="timeBlockYearly-input-btn-paste"
    >
      Paste Data
    </button>
  }
</div>
@if (isLoading()) {
  <div class="text-center py-3">
    <mat-spinner diameter="80" color="accent" class="inline-block">
    </mat-spinner>
  </div>
} @else {
  <cdk-virtual-scroll-viewport
    itemSize="30"
    minBufferPx="100"
    maxBufferPx="100"
    style="height: 350px"
  >
    <table>
      <thead>
        @for (column of columnDef(); track column.key) {
          <th class="text-left">
            {{ column.name }}
            @if (column.tooltip) {
              <prosumer-tooltip-anchor
                [message]="column.tooltip"
                [tooltipPosition]="'right'"
                [attr.data-testid]="
                  'timeBlockYearly-table-header-tooltip-' + column.name
                "
              >
              </prosumer-tooltip-anchor>
            }
          </th>
        }
      </thead>
      <tbody>
        <tr
          *cdkVirtualFor="
            let el of dataSource();
            let i = index;
            trackBy: trackByFn
          "
          cdkVirtualForTemplateCacheSize="0"
        >
          @for (column of columnDef(); track column.key) {
            <td>
              @if (column.role === 'input') {
                @switch (column.inputType) {
                  @case ('number') {
                    <mat-form-field appearance="outline" class="block w-8rem">
                      <input
                        matInput
                        [type]="column.inputType"
                        [disabled]="isViewOnly()"
                        [formControl]="
                          tbYearlyInputArrayForm?.at(i)?.controls[column.key]
                        "
                        [attr.data-testid]="
                          'timeBlockYearly-input-' + column.key + i
                        "
                      />
                    </mat-form-field>
                  }
                  @case ('checkbox') {
                    <mat-checkbox
                      class="w-5rem -mt-3 text-center"
                      [formControl]="
                        tbYearlyInputArrayForm?.at(i)?.controls[column.key]
                      "
                      [attr.data-testid]="
                        'timeBlockYearly-input-' + column.key + i
                      "
                    ></mat-checkbox>
                  }
                }
              } @else {
                <div
                  class="w-14rem"
                  [attr.data-testid]="
                    'timeBlockYearly-description-' + column.key + i
                  "
                >
                  {{ el[column.key] }}
                </div>
              }
            </td>
          }
        </tr>
      </tbody>
    </table>
  </cdk-virtual-scroll-viewport>
}
