import { ReserveDefinitionI } from 'prosumer-app/+scenario/models/reserve-definition.model';
import { VariationFinder } from 'prosumer-app/+scenario/services/variation-finder.service';

import { inject, Injectable } from '@angular/core';
import { StoreConfig } from '@datorama/akita';

import { ScenarioDetailStore, ScenarioDetailType } from '../scenario-detail';
import { NO_VARIATION } from '../scenario-variation/scenario-variation.state';
import { ReserveDefinitionStateI } from './reserve-definition.state';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: ScenarioDetailType.reserveDefinition, idKey: 'id' })
export class ReserveDefinitionStore extends ScenarioDetailStore<ReserveDefinitionStateI> {
  private readonly varFinder = inject(VariationFinder);

  // cascade feature
  // edit(id: string, dt: ReserveDefinitionStateI) {
  //   return this.cascadeEdit(id, dt);
  // }

  toFE(from: ReserveDefinitionI): ReserveDefinitionStateI {
    return {
      ...from,
      scenarioVariation: this.determineVariation(from.scenarioVariation),
    };
  }

  toBE(from: ReserveDefinitionStateI): ReserveDefinitionI {
    return this.modifyVarIfBasecase(from);
  }

  private modifyVarIfBasecase(d: ReserveDefinitionStateI) {
    if (d.scenarioVariation === NO_VARIATION) d.scenarioVariation = null;
    return d as ReserveDefinitionStateI;
  }

  private determineVariation(variationId: string): string {
    return variationId
      ? variationId
      : this.varFinder.getVariationName(variationId);
  }
}
