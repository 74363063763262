import { ReserveDefinitionI } from 'prosumer-app/+scenario/models/reserve-definition.model';
import { TimePartitionI } from 'prosumer-app/+scenario/models/time-partition.model';
import { toObject } from 'prosumer-app/libs/eyes-shared/utilities/object.util';
import {
  EnergyVectorInfo,
  EnergyVectorQuery,
} from 'prosumer-app/stores/energy-vector';
import { ReserveDefinitionQuery } from 'prosumer-app/stores/reserve-definition';
import { ScenarioVariationInfo } from 'prosumer-app/stores/scenario-variation';
import { ScenarioVariationQuery } from 'prosumer-app/stores/scenario-variation/scenario-variation.query';
import { TimePartitionQuery } from 'prosumer-app/stores/time-partition/time-partition.query';
import { combineLatest, map } from 'rxjs';

import { inject, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ReservesRefBuilderService {
  private readonly variations = inject(ScenarioVariationQuery);
  private readonly timePartition = inject(TimePartitionQuery);
  private readonly resDef = inject(ReserveDefinitionQuery);
  private readonly ev = inject(EnergyVectorQuery);

  selectReserveRefs() {
    return combineLatest([
      this.variations.selectAllForActiveScenario(),
      this.timePartition.selectAllForActiveScenario(),
      this.resDef.selectAllForActiveScenario(),
      this.ev.selectAllForActiveScenario(),
    ]).pipe(map((dt) => this.mapRefs(dt)));
  }

  private mapRefs([a, b, c, d]) {
    return {
      scenarioVariations: this.objectifyVariations(a),
      timePartitions: this.objectifyTimePartition(b),
      reserveDefinitions: this.objectifyResDef(c),
      energyVectors: this.objectifyEV(d),
    };
  }

  private objectifyEV(ev: EnergyVectorInfo[]): Record<string, string> {
    return this.objectifyUsingId(ev, 'energyVectorId');
  }

  private objectifyVariations(
    variations: ScenarioVariationInfo[],
  ): Record<string, string> {
    return this.objectifyUsingId(variations, 'variationUuid');
  }

  private objectifyTimePartition(
    timePartitions: TimePartitionI[],
  ): Record<string, string> {
    return this.objectifyUsingId(timePartitions, 'id', 'name');
  }

  private objectifyResDef(
    resDef: ReserveDefinitionI[],
  ): Record<string, string> {
    return this.objectifyUsingId(resDef, 'id', 'name');
  }

  private objectifyUsingId<T>(
    list: T[],
    id: keyof T,
    displayKey = 'name',
  ): Record<string, string> {
    return toObject(list, id as string, displayKey);
  }
}
