@if (isLoading() || onEditLoading()) {
  <mat-progress-bar
    color="accent"
    mode="indeterminate"
    data-testid="meterDefinition-dialog-progress-bar"
  ></mat-progress-bar>
}

<form
  [formGroup]="meterDefinitionForm"
  data-testid="meterDefinition-dialog-form"
>
  <h2 mat-dialog-title fxLayout="row" class="container-title pt-3 mb-2">
    <span data-testid="meterDefinition-dialog-title">
      {{ data?.mode | titlecase }}
      {{ 'Scenario.dialog.meters.labels.meterDefinition.title' | translate }}
    </span>
    <prosumer-tooltip-anchor
      [message]="'wizard_meters.wizard_meters_meter_definitions'"
      data-testid="meterDefinition-dialog-title-tooltip"
    ></prosumer-tooltip-anchor>
  </h2>

  <mat-dialog-content class="container-content mb-4">
    <div fxLayout="column" class="gap-1">
      <prosumer-input
        [label]="
          'Scenario.dialog.meters.labels.meterDefinition.name' | translate
        "
        [placeholder]="
          'Scenario.dialog.meters.placeholders.meterDefinition.name' | translate
        "
        [required]="true"
        [control]="meterDefinitionForm?.controls.name"
        [errorMessageMap]="errorMessages?.name"
        data-testid="meterDefinition-dialog-form-name"
      >
      </prosumer-input>

      <prosumer-select
        [required]="true"
        [options]="timePartitionsDt()"
        [control]="meterDefinitionForm?.controls?.timePartitionId"
        [label]="
          'Scenario.dialog.meters.labels.meterDefinition.timePartitionId'
            | translate
        "
        [placeholder]="
          'Scenario.dialog.meters.placeholders.meterDefinition.timePartitionId'
            | translate
        "
        [tooltip]="
          'wizard_meters.wizard_meters_meter_definitions_time_partition'
        "
        [errorMessageMap]="errorMessages.timePartitionId"
        data-testid="meterDefinition-dialog-form-timePartitionId"
      >
      </prosumer-select>

      @if (scenarioVariationsDt()) {
        <prosumer-select
          [placeholder]="
            'Scenario.dialog.meters.placeholders.meterDefinition.scenarioVariation'
              | translate
          "
          [label]="
            'Scenario.dialog.meters.labels.meterDefinition.scenarioVariation'
              | translate
          "
          [options]="scenarioVariationsDt()"
          [control]="meterDefinitionForm?.controls?.scenarioVariation"
          [errorMessageMap]="errorMessages.scenarioVariation"
          data-testid="meterDefinition-dialog-form-scenarioVariation"
        ></prosumer-select>
      }

      <prosumer-checkbox
        [label]="
          'Scenario.dialog.meters.labels.meterDefinition.onlyCountPositiveValues'
            | translate
        "
        [tooltip]="
          'wizard_meters.wizard_meters_meter_definitions_count_only_positive_values'
        "
        [control]="meterDefinitionForm?.controls?.onlyCountPositiveValues"
        data-testid="meterDefinition-dialog-form-onlyCountPositiveValues"
      ></prosumer-checkbox>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row" class="gap-2 pb-5">
    @if (!data.isViewOnly) {
      <prosumer-entity-upsert-button
        [id]="data.entity?.id"
        [mode]="data.mode"
        [valid]="meterDefinitionForm.valid"
        [disabled]="meterDefinitionForm.invalid"
        [data]="meterDefinitionForm.getRawValue()"
        (ok)="onClose(data.mode)"
        data-testid="meterDefinition-dialog-form-btn-submit"
      ></prosumer-entity-upsert-button>
    }
    <button
      mat-flat-button
      type="button"
      [disabled]="isLoading() || onEditLoading()"
      (click)="onClose()"
      data-testid="meterDefinition-dialog-form-btn-cancel"
    >
      {{ 'Generic.labels.close' | translate }}
    </button>
  </mat-dialog-actions>
</form>
