import { ResBidActivationStore } from 'prosumer-app/stores/res-bid-activation';
import {
  ReserveDefinitionQuery,
  ReserveDefinitionStateI,
  ReserveDefinitionStore,
} from 'prosumer-app/stores/reserve-definition';
import { delay, filter, forkJoin, map, Observable, of, take } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

const DELAY_TIME_FETCH_AFFECTED = 2000;

@Injectable({ providedIn: 'root' })
export class ReserveDefinitionService {
  private store = inject(ReserveDefinitionStore);
  private query = inject(ReserveDefinitionQuery);
  private storeResBidActivation = inject(ResBidActivationStore);

  isLoading() {
    return this.query.selectLoading();
  }

  isEntityLoading(id: string): Observable<boolean> {
    if (!id) return of(false);
    return this.query.selectEntity(id).pipe(
      filter((d) => !!d),
      map((e) => e.loading),
    );
  }

  selectAllForActiveScenario() {
    return this.query.selectAllForActiveScenario();
  }

  getEntity(id: string) {
    return this.query.getEntity(id);
  }

  getSingle(id: string) {
    return this.store.improvedGetSingle(id);
  }

  patchForm(e: ReserveDefinitionStateI, form: UntypedFormGroup) {
    form.patchValue({
      name: e.name,
      scenarioVariation: e.scenarioVariation,
      energyVectorId: e.energyVectorId,
      timePartitionId: e.timePartitionId,
    });
  }

  deleteOne(id: string) {
    this.store
      .deleteOne(id)
      .pipe(take(1), delay(DELAY_TIME_FETCH_AFFECTED))
      .subscribe(() => this.fetchAffectedEntities());
  }

  selectMappedForFormSelection() {
    return this.selectAllForActiveScenario().pipe(
      map((l) => this.mapToFormSelection(l)),
    );
  }

  private mapToFormSelection(l: ReserveDefinitionStateI[]) {
    return l.map((e) => ({ ...e, value: e.id }));
  }

  private fetchAffectedEntities() {
    forkJoin([this.storeResBidActivation.improvedGetAll()])
      .pipe(take(1))
      .subscribe();
  }
}
