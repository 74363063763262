<prosumer-sparkline-table
  parentType="detail"
  detailType="reserveDefinition"
  [data]="data()"
  [references]="refsDt()"
  [searchInput]="true"
  [columnsDef]="columnsDef"
  [loading]="isLoading()"
  [hideAdd]="isViewOnly()"
  [hideView]="!isViewOnly()"
  [hideEdit]="isViewOnly()"
  [hideDelete]="isViewOnly()"
  (delete)="onDelete($event)"
  (add)="onOpenDialog(isViewOnly(), 'add')"
  (edit)="onOpenDialog(isViewOnly(), 'edit', $event)"
  data-testid="reserve-definition-table"
>
</prosumer-sparkline-table>
