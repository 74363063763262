import { ResBidActivationI } from 'prosumer-app/+scenario/models/res-bid-activation.model';
import { ResBidActivationInfoService } from 'prosumer-app/+scenario/services/res-bid-activation-info';
import { DialogService } from 'prosumer-app/libs/eyes-core/services/dialog.service';
import { TableRefs } from 'prosumer-app/services/references-builder/references.builder.types';
import { SharedModule } from 'prosumer-app/shared/shared.module';
import { take } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { colDefResBidActivation } from '../config/res-bid-activation.token';
import { ResBidActivationDialogComponent } from '../res-bid-activation-dialog';

@Component({
  selector: 'prosumer-res-bid-activation-table',
  imports: [CommonModule, SharedModule],
  templateUrl: './res-bid-activation-table.component.html',
  styleUrl: './res-bid-activation-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResBidActivationTableComponent {
  private readonly dialogService = inject(DialogService);
  private readonly infoService = inject(ResBidActivationInfoService);

  readonly isViewOnly = input<boolean>();
  readonly refsDt =
    input<Pick<TableRefs, 'reserveDefinitions' | 'scenarioVariations'>>();

  readonly columnsDef = colDefResBidActivation;

  readonly data = toSignal(this.infoService.selectAllForActiveScenario());
  readonly isLoading = toSignal(this.infoService.isLoading());

  onOpenDialog(
    isViewOnly: boolean,
    mode: 'edit' | 'add',
    e: ResBidActivationI = null,
  ) {
    this.dialogService
      .openDialog(ResBidActivationDialogComponent, {
        width: 1000,
        mode,
        isViewOnly: isViewOnly,
        entity: e,
      })
      .pipe(take(1))
      .subscribe();
  }

  onDelete(e: ResBidActivationI) {
    this.infoService.deleteOne(e.id);
  }
}
