import { ColumnDefinition } from 'prosumer-libs/eyes-shared/components/base-table/base-table.model';

export const colDefReserverDefinition: ColumnDefinition = {
  name: {
    name: 'Name',
    sortable: true,
  },
  energyVectorId: {
    name: 'Energy Vector',
    type: 'reference',
    referenceKey: 'energyVectors',
    // sortable: true,
  },
  timePartitionId: {
    name: 'Time Partition',
    type: 'reference',
    referenceKey: 'timePartitions',
    sortable: true,
  },
  scenarioVariation: {
    name: 'Variation',
    type: 'reference',
    referenceKey: 'scenarioVariations',
    // sortable: true,
  },
  actions: {
    name: 'Actions',
    type: 'action',
    flex: '160px',
  },
};
