import { ColumnDefinition } from 'prosumer-libs/eyes-shared/components/base-table/base-table.model';

export const colDefResBidActivation: ColumnDefinition = {
  reserveId: {
    name: 'Reserve Definitions',
    type: 'reference',
    referenceKey: 'reserveDefinitions',
    sortable: true,
  },
  scenarioVariation: {
    name: 'Variation',
    type: 'reference',
    referenceKey: 'scenarioVariations',
    // sortable: true,
  },
  actions: {
    name: 'Actions',
    type: 'action',
    flex: '160px',
  },
};
