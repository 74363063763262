import {
  ResBidActivationQuery,
  ResBidActivationStateI,
  ResBidActivationStore,
} from 'prosumer-app/stores/res-bid-activation';
import { delay, filter, map, Observable, of, take } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

const DELAY_TIME_FETCH_AFFECTED = 2000;

@Injectable({ providedIn: 'root' })
export class ResBidActivationInfoService {
  private readonly store = inject(ResBidActivationStore);
  private readonly query = inject(ResBidActivationQuery);

  isLoading() {
    return this.query.selectLoading();
  }

  isEntityLoading(id: string): Observable<boolean> {
    if (!id) return of(false);
    return this.query.selectEntity(id).pipe(
      filter((d) => !!d),
      map((e) => e.loading),
    );
  }

  selectAllForActiveScenario() {
    return this.query.selectAllForActiveScenario();
  }

  getSingle(id: string) {
    return this.store.improvedGetSingle(id);
  }

  patchForm(e: ResBidActivationStateI, form: UntypedFormGroup) {
    form.patchValue({
      reserveId: e.reserveId,
      profiles: e.profiles,
      scenarioVariation: e.scenarioVariation,
    });
  }

  deleteOne(id: string) {
    this.store
      .deleteOne(id)
      .pipe(take(1), delay(DELAY_TIME_FETCH_AFFECTED))
      .subscribe();
    // () => this.fetchAffectedEntities());
  }

  // private fetchAffectedEntities() {
  //   forkJoin([of(1)])
  //     .pipe(take(1))
  //     .subscribe();
  // }
}
