import {
  BidAndActivationdProfileDataI,
  BidAndActivationValuesType,
  ResBidActivationI,
} from 'prosumer-app/+scenario/models/res-bid-activation.model';
import { VariationFinder } from 'prosumer-app/+scenario/services/variation-finder.service';

import { inject, Injectable } from '@angular/core';
import { StoreConfig } from '@datorama/akita';

import { ScenarioDetailStore, ScenarioDetailType } from '../scenario-detail';
import { NO_VARIATION } from '../scenario-variation/scenario-variation.state';
import {
  ResBidActivationProfileDataStateI,
  ResBidActivationStateI,
} from './res-bid-activation.state';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: ScenarioDetailType.reserveBidAndActivation, idKey: 'id' })
export class ResBidActivationStore extends ScenarioDetailStore<ResBidActivationStateI> {
  private readonly varFinder = inject(VariationFinder);

  toFE(from: ResBidActivationI): ResBidActivationStateI {
    const { bidAndActivationValues, ...to } = {
      ...from,
      scenarioVariation: this.determineVariation(from.scenarioVariation),
      profiles: this.mapBidAndActivationValuesToProfiles(
        from.bidAndActivationValues,
      ),
    };
    return to;
  }

  mapBidAndActivationValuesToProfiles(
    d: BidAndActivationValuesType,
  ): ResBidActivationProfileDataStateI[] {
    return d?.profiles?.map((p) => this.mapProfileToFE(p));
  }

  mapProfileToFE(
    profile: BidAndActivationdProfileDataI,
  ): ResBidActivationProfileDataStateI {
    const { duration, year, ...p } = {
      ...profile,
      endYear: profile.year + profile.duration - 1,
      startYear: profile.year,
    };
    return p;
  }

  toBE(from: ResBidActivationStateI): ResBidActivationI {
    const { profiles, ...to } = {
      ...from,
      scenarioVariation: this.modifyVarIfBasecase(from.scenarioVariation),
      bidAndActivationValues: this.mapProfilesToBidAndActivationValues(
        from.profiles,
      ),
    };
    return to;
  }

  mapProfilesToBidAndActivationValues(
    profiles: ResBidActivationProfileDataStateI[],
  ): BidAndActivationValuesType {
    return {
      name: 'Reserve and Bid Activation Profiles',
      profiles: profiles?.map((p) => this.mapProfileToBE(p)),
    };
  }

  mapProfileToBE(
    profile: ResBidActivationProfileDataStateI,
  ): BidAndActivationdProfileDataI {
    const { startYear, endYear, data, ...p } = {
      ...profile,
      duration: profile.endYear - profile.startYear + 1,
      year: profile.startYear,
    };
    return p;
  }

  private modifyVarIfBasecase(v: string) {
    if (v === NO_VARIATION) return null;
    return v;
  }

  private determineVariation(variationId: string): string {
    return variationId
      ? variationId
      : this.varFinder.getVariationName(variationId);
  }
}
