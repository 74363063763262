import { ReserveDefinitionI } from 'prosumer-app/+scenario/models/reserve-definition.model';
import { ReserveDefinitionService } from 'prosumer-app/+scenario/services/reserve-definition';
import { DialogService } from 'prosumer-app/libs/eyes-core/services/dialog.service';
import { TableRefs } from 'prosumer-app/services/references-builder/references.builder.types';
import { SharedModule } from 'prosumer-app/shared/shared.module';
import { take } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { colDefReserverDefinition } from '../config/reserve-definition.token';
import { ReserveDefinitionDialogComponent } from '../reserve-definition-dialog';

@Component({
  selector: 'prosumer-reserves-definition-table',
  imports: [CommonModule, SharedModule],
  templateUrl: './reserves-definition-table.component.html',
  styleUrl: './reserves-definition-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReservesDefinitionTableComponent {
  private dialogService = inject(DialogService);
  private infoService = inject(ReserveDefinitionService);

  isViewOnly = input<boolean>();
  refsDt =
    input<
      Pick<TableRefs, 'timePartitions' | 'scenarioVariations' | 'energyVectors'>
    >();

  readonly columnsDef = colDefReserverDefinition;
  data = toSignal(this.infoService.selectAllForActiveScenario());
  isLoading = toSignal(this.infoService.isLoading());

  onOpenDialog(
    isViewOnly: boolean,
    mode: 'edit' | 'add',
    e: ReserveDefinitionI = null,
  ) {
    this.dialogService
      .openDialog(ReserveDefinitionDialogComponent, {
        width: 750,
        mode,
        isViewOnly: isViewOnly,
        entity: e,
      })
      .pipe(take(1))
      .subscribe();
  }

  onDelete(e: ReserveDefinitionI) {
    this.infoService.deleteOne(e.id);
  }
}
