@if (isLoading() || isLoadingSingleEntity()) {
  <mat-progress-bar
    color="accent"
    mode="indeterminate"
    data-testid="resBidActivation-dialog-progress-bar"
  ></mat-progress-bar>
}

<form
  [formGroup]="resBidActivationForm"
  data-testid="resBidActivation-dialog-form"
>
  <h2 mat-dialog-title fxLayout="row" class="container-title pt-3 mb-2">
    <span data-testid="resBidActivation-dialog-title">
      {{ data?.mode | titlecase }}
      {{ 'Scenario.dialog.reserves.labels.resBidActivation.title' | translate }}
    </span>
    <prosumer-tooltip-anchor
      [message]="'wizard_reserves.wizard_reserves_res_bid_activation'"
      data-testid="resBidActivation-dialog-title-tooltip"
    ></prosumer-tooltip-anchor>
  </h2>

  <mat-dialog-content class="container-content mb-4">
    <div class="formgrid grid">
      <div class="field col-6 mb-1">
        <prosumer-select
          [required]="true"
          [options]="resDefDt()"
          [control]="resBidActivationForm?.controls?.reserveId"
          [label]="
            'Scenario.dialog.reserves.labels.resBidActivation.reserveId'
              | translate
          "
          [placeholder]="
            'Scenario.dialog.reserves.placeholders.resBidActivation.reserveId'
              | translate
          "
          [tooltip]="
            'wizard_reserves.wizard_reserves_res_bid_activation_reserveId'
          "
          [errorMessageMap]="errorMessages.reserveId"
          data-testid="resBidActivation-dialog-form-reserveId"
        >
        </prosumer-select>
      </div>
      <div class="field col-6 mb-1">
        @if (scenarioVariationsDt()) {
          <prosumer-select
            [required]="true"
            [placeholder]="
              'Scenario.dialog.reserves.placeholders.resBidActivation.scenarioVariation'
                | translate
            "
            [label]="
              'Scenario.dialog.reserves.labels.resBidActivation.scenarioVariation'
                | translate
            "
            [options]="scenarioVariationsDt()"
            [control]="resBidActivationForm?.controls?.scenarioVariation"
            [errorMessageMap]="errorMessages.scenarioVariation"
            data-testid="resBidActivation-dialog-form-scenarioVariation"
          ></prosumer-select>
        }
      </div>
      <div class="field col-12 mb-1">
        <prosumer-time-blocks-interval
          [columnDef]="columnDef"
          [formControl]="resBidActivationForm?.get('profiles')"
          [startYearPeriod]="activePeriod()[0]"
          [endYearPeriod]="activePeriod()[1]"
          [binaryLocation]="profilesDataLocation"
          [emptyDataMesssage]="
            'Scenario.dialog.reserves.labels.resBidActivation.emptyUniqueTimeBlocksList'
              | translate
          "
          data-testid="resBidActivation-dialog-form-timeBlocks"
        ></prosumer-time-blocks-interval>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="flow gap-2 pt-3 pb-5">
    @if (!data.isViewOnly) {
      <prosumer-entity-upsert-button
        [id]="data?.entity?.id"
        [mode]="data?.mode"
        [data]="resBidActivationForm.value"
        [valid]="resBidActivationForm.valid"
        [disabled]="
          resBidActivationForm.pristine || resBidActivationForm.invalid
        "
        (ok)="onClose()"
      ></prosumer-entity-upsert-button>
    }
    <button
      mat-flat-button
      type="button"
      [disabled]="isLoading() || isLoadingSingleEntity()"
      (click)="onClose()"
      data-testid="resBidActivation-dialog-form-btn-cancel"
    >
      {{ 'Generic.labels.close' | translate }}
    </button>
  </mat-dialog-actions>
</form>
