export const RES_BID_ACTIVATION_TB_COLUMN_DEF = [
  {
    name: 'Time Blocks',
    key: 'timeBlock',
    role: 'description',
    tooltip: 'wizard_reserves.wizard_reserves_res_bid_activation_time_block',
  },
  {
    name: 'Min Bid Up',
    key: 'minBidUp',
    role: 'input',
    inputType: 'number',
  },
  {
    name: 'Max Bid Up',
    key: 'maxBidUp',
    role: 'input',
    inputType: 'number',
  },
  {
    name: 'Min Bid Down',
    key: 'minBidDown',
    role: 'input',
    inputType: 'number',
  },
  {
    name: 'Max Bid Down',
    key: 'maxBidDown',
    role: 'input',
    inputType: 'number',
  },
  {
    name: 'Forced Bid',
    key: 'forcedBid',
    role: 'input',
    inputType: 'checkbox',
  },
  {
    name: 'Bid Earning',
    key: 'bidEarning',
    role: 'input',
    inputType: 'number',
  },
];
