<mat-expansion-panel [expanded]="expanded">
  <mat-expansion-panel-header
    [collapsedHeight]="collapsedHeight"
    [expandedHeight]="expandedHeight"
  >
    <mat-panel-title data-testid="reserve-definition-panel-title">
      {{
        'Scenario.labels.wizard.reserves.headers.reserveDefinition' | translate
      }}
      <prosumer-tooltip-anchor
        [message]="'wizard_reserves.wizard_reserves_reserve_definition'"
      >
      </prosumer-tooltip-anchor>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <prosumer-reserves-definition-table
    [isViewOnly]="isViewMode()"
    [refsDt]="refsReserves()"
  ></prosumer-reserves-definition-table>
</mat-expansion-panel>

<mat-expansion-panel [expanded]="expanded">
  <mat-expansion-panel-header
    [collapsedHeight]="collapsedHeight"
    [expandedHeight]="expandedHeight"
  >
    <mat-panel-title data-testid="res-bid-activation-panel-title">
      {{
        'Scenario.labels.wizard.reserves.headers.reserveBidAndActivation'
          | translate
      }}
      <prosumer-tooltip-anchor
        [message]="'wizard_reserves.wizard_reserves_res_bid_activation'"
      >
      </prosumer-tooltip-anchor>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <prosumer-res-bid-activation-table
    [isViewOnly]="isViewMode()"
    [refsDt]="refsReserves()"
  >
  </prosumer-res-bid-activation-table>
</mat-expansion-panel>

<prosumer-stepper-nav
  [disabled]="disabled"
  [previousDisabled]="previousDisabled"
  [previousLabel]="previousLabel"
  [previousColor]="previousColor"
  [nextDisabled]="nextDisabled"
  [nextLabel]="nextLabel"
  [nextColor]="nextColor"
  [reverseButtons]="reverseButtons"
  (previous)="onClickPrevious()"
  (next)="onClickNext()"
  data-testid="reserves-form-stepper-nav"
>
</prosumer-stepper-nav>
