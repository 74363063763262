import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { Observable } from 'rxjs';

import { inject, Injectable } from '@angular/core';

import { WithProfilesCreator } from '../creator';
import { ProfileStore } from '../profile';
import { ResBidActivationStateI } from './res-bid-activation.state';
import { ResBidActivationStore } from './res-bid-activation.store';

@Injectable({ providedIn: 'root' })
export class ResBidActivationCreator extends WithProfilesCreator<ResBidActivationStateI> {
  private readonly store = inject(ResBidActivationStore);

  constructor() {
    const profileStore = inject(ProfileStore);
    const keeper = inject(ActiveKeeperService);
    super(profileStore, keeper);
  }

  createEntity(d: ResBidActivationStateI): Observable<unknown> {
    return this.store.create(d);
  }

  updateEntity(d: ResBidActivationStateI) {
    return this.store.edit(d.id, d);
  }
}
