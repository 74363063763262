@if (isLoading() || isLoadingSingleEntity()) {
  <mat-progress-bar
    color="accent"
    mode="indeterminate"
    data-testid="reserveDefinition-dialog-progress-bar"
  ></mat-progress-bar>
}

<form
  [formGroup]="reserveDefinitionForm"
  data-testid="reserveDefinition-dialog-form"
>
  <h2 mat-dialog-title class="container-title py-3 mb-2">
    <span data-testid="reserveDef-dialog-title">
      {{ data?.mode | titlecase }}
      {{ 'Scenario.dialog.reserves.labels.reserveDef.title' | translate }}
    </span>
    <prosumer-tooltip-anchor
      [message]="'wizard_reserves.wizard_reserves_reserve_definition'"
      data-testid="reserveDef-dialog-title-tooltip"
    ></prosumer-tooltip-anchor>
  </h2>

  <mat-dialog-content class="container-content mb-4">
    <div class="formgrid grid">
      <div class="field col-6 mb-1">
        <prosumer-input
          [label]="
            'Scenario.dialog.reserves.labels.reserveDef.name' | translate
          "
          [placeholder]="
            'Scenario.dialog.reserves.placeholders.reserveDef.name' | translate
          "
          [required]="true"
          [control]="reserveDefinitionForm?.controls.name"
          [errorMessageMap]="errorMessages?.name"
          data-testid="reserveDef-dialog-form-name"
        >
        </prosumer-input>
      </div>
      <div class="field col-6 mb-1">
        @if (scenarioVariationsDt()) {
          <prosumer-select
            [placeholder]="
              'Scenario.dialog.reserves.placeholders.reserveDef.scenarioVariation'
                | translate
            "
            [label]="
              'Scenario.dialog.reserves.labels.reserveDef.scenarioVariation'
                | translate
            "
            [options]="scenarioVariationsDt()"
            [control]="reserveDefinitionForm?.controls?.scenarioVariation"
            [errorMessageMap]="errorMessages.scenarioVariation"
            data-testid="reserveDef-dialog-form-scenarioVariation"
          ></prosumer-select>
        }
      </div>
      <div class="field col-6 mb-1">
        <prosumer-select
          [required]="true"
          [options]="timePartitionsDt()"
          [control]="reserveDefinitionForm?.controls?.timePartitionId"
          [label]="
            'Scenario.dialog.reserves.labels.reserveDef.timePartitionId'
              | translate
          "
          [placeholder]="
            'Scenario.dialog.reserves.placeholders.reserveDef.timePartitionId'
              | translate
          "
          [tooltip]="
            'wizard_reserves.wizard_reserves_reserve_definition_time_partition'
          "
          [errorMessageMap]="errorMessages.timePartitionId"
          data-testid="reserves-dialog-form-timePartitionId"
        >
        </prosumer-select>
      </div>
      <div class="field col-6 mb-1">
        <prosumer-select
          [required]="true"
          [options]="evDt()"
          [placeholder]="'Scenario.placeholders.energyVector' | translate"
          [label]="'Scenario.labels.energyVector' | translate"
          [control]="reserveDefinitionForm?.controls?.energyVectorId"
          [errorMessageMap]="errorMessages?.energyVectorId"
          [tooltip]="
            'wizard_reserves.wizard_reserves_reserve_definition_energy_vector'
              | translate
          "
          data-testid="reserves-dialog-form-energyVectorId"
        ></prosumer-select>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="flex gap-2 pb-5">
    @if (!data.isViewOnly) {
      <prosumer-entity-upsert-button
        [id]="data?.entity?.id"
        [mode]="data?.mode"
        [data]="reserveDefinitionForm.value"
        [valid]="reserveDefinitionForm.valid"
        [disabled]="
          reserveDefinitionForm.pristine || reserveDefinitionForm.invalid
        "
        (ok)="onClose()"
        data-testid="reserves-dialog-form-btn-ok"
      ></prosumer-entity-upsert-button>
    }
    <button
      mat-flat-button
      type="button"
      [disabled]="isLoading() || isLoadingSingleEntity()"
      (click)="onClose()"
      data-testid="reserves-dialog-form-btn-cancel"
    >
      {{ 'Generic.labels.close' | translate }}
    </button>
  </mat-dialog-actions>
</form>
