import {
  BidAndActivationBinaryDataI,
  ResBidActivationDialogData,
} from 'prosumer-app/+scenario/models/res-bid-activation.model';
import { ResBidActivationInfoService } from 'prosumer-app/+scenario/services/res-bid-activation-info';
import { ReserveDefinitionService } from 'prosumer-app/+scenario/services/reserve-definition';
import { TimePartitionInfoService } from 'prosumer-app/+scenario/services/time-partition-info';
import { VariationInfoService } from 'prosumer-app/+scenario/services/variation-info';
import { BINARY_LOCATIONS } from 'prosumer-app/app.references';
import {
  FormFieldErrorMessageMap,
  FormFieldOption,
} from 'prosumer-app/libs/eyes-shared/components/form-field';
import { EyesSharedModule } from 'prosumer-app/libs/eyes-shared/eyes-shared.module';
import { TimeBlocksIntervalComponent } from 'prosumer-app/shared/components/time-blocks-interval';
import { SharedModule } from 'prosumer-app/shared/shared.module';
import { ResBidActivationCreator } from 'prosumer-app/stores/res-bid-activation';
import { provideUpserter } from 'prosumer-app/stores/scenario-detail/scenario-detail.tokens';
import { ScenarioGenericQuery } from 'prosumer-app/stores/scenario-generic';
import { EntityUpsertButtonModule } from 'prosumer-shared/components/entity-upsert-button/entity-upsert-button.module';
import { skip, take } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  OnInit,
  signal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  ReactiveFormsModule,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslateModule } from '@ngx-translate/core';

import { RES_BID_ACTIVATION_TB_COLUMN_DEF } from './config';

@Component({
  selector: 'prosumer-res-bid-activation-dialog',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    EyesSharedModule,
    SharedModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatFormFieldModule,
    MatProgressBarModule,
    TimeBlocksIntervalComponent,
    EntityUpsertButtonModule,
  ],
  providers: [provideUpserter(ResBidActivationCreator)],
  templateUrl: './res-bid-activation-dialog.component.html',
  styleUrl: './res-bid-activation-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResBidActivationDialogComponent implements OnInit {
  private resBidActivationInfo = inject(ResBidActivationInfoService);
  private timePartitionInfo = inject(TimePartitionInfoService);
  private resDefInfo = inject(ReserveDefinitionService);
  private variationInfo = inject(VariationInfoService);
  private scenarioQuery = inject(ScenarioGenericQuery);
  private destroyRef = inject(DestroyRef);
  private fb = inject(FormBuilder);
  dialogRef = inject(MatDialogRef<ResBidActivationDialogComponent>);
  data = inject<ResBidActivationDialogData>(MAT_DIALOG_DATA);

  isLoadingSingleEntity = toSignal(
    this.resBidActivationInfo.isEntityLoading(this.data?.entity?.id),
  );
  isLoading = toSignal(this.resBidActivationInfo.isLoading());
  resDefDt = toSignal(this.resDefInfo.selectMappedForFormSelection());
  activePeriod = toSignal(this.scenarioQuery.selectActivePeriod());
  scenarioVariationsDt = signal<FormFieldOption<string>[]>(null);

  resBidActivationForm: UntypedFormGroup;
  readonly profilesDataLocation =
    BINARY_LOCATIONS.RESERVE_BID_AND_ACTIVATION_VALUES_LOCATION;
  readonly columnDef = RES_BID_ACTIVATION_TB_COLUMN_DEF;

  ngOnInit() {
    this.initForm();
    this.handleScenarioVariationsExist();
    this.subToResDefSelectionChange();
    this.handleEntityOnEditMode(this.data);
  }

  onClose() {
    this.dialogRef.close();
  }

  private initForm() {
    this.resBidActivationForm = this.fb.group({
      reserveId: this.fb.control('', [Validators.required]),
      profiles: this.fb.control([], [Validators.required]),
    });
  }

  private handleScenarioVariationsExist() {
    const vars = this.variationInfo.getAllForActiveScenario();
    if (vars.length > 1) {
      this.scenarioVariationsDt.set(vars);
      this.registerScenarioVariationCtrl(vars);
    }
  }

  private registerScenarioVariationCtrl(variations: FormFieldOption<string>[]) {
    this.resBidActivationForm.addControl(
      'scenarioVariation',
      this.fb.control(variations[0].value, [Validators.required]),
    );
  }

  private handleEntityOnEditMode(dt: ResBidActivationDialogData) {
    if (dt.mode === 'edit' && dt.entity) {
      this.resBidActivationInfo
        .getSingle(dt.entity.id)
        .pipe(take(1))
        .subscribe((e) =>
          this.resBidActivationInfo.patchForm(e, this.resBidActivationForm),
        );
    }
  }

  private subToResDefSelectionChange() {
    this.resBidActivationForm
      .get('reserveId')
      .valueChanges.pipe(
        skip(this.data.mode === 'edit' ? 1 : 0),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((mdId) => this.fetchUniqueTimeBlocks(mdId));
  }

  private fetchUniqueTimeBlocks(resDefId: string) {
    const tpId = this.resDefInfo.getEntity(resDefId)['timePartitionId'];
    this.timePartitionInfo
      .getUniqueTBsOfATimePartition(tpId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((tp) => {
        this.resBidActivationForm
          .get('profiles')
          .patchValue([{ data: this.mapTimeBlocksToCtrlFormat(tp) }]);
      });
  }

  private mapTimeBlocksToCtrlFormat(
    d: string[],
  ): BidAndActivationBinaryDataI[] {
    return d.map((e) => ({
      timeBlock: e,
      minBidUp: null,
      maxBidUp: null,
      minBidDown: null,
      maxBidDown: null,
      forcedBid: false,
      bidEarning: null,
    }));
  }

  readonly errorMessages: FormFieldErrorMessageMap = {
    reserveId: {},
    profiles: {},
    scenarioVariation: {},
  };
}
