@if (isLoading() || onEditLoading()) {
  <mat-progress-bar
    color="accent"
    mode="indeterminate"
    data-testid="meterCostAndBounds-dialog-progress-bar"
  ></mat-progress-bar>
}

<form
  [formGroup]="meterCostAndBoundForm"
  data-testid="meterCostAndBound-dialog-form"
>
  <h2 mat-dialog-title fxLayout="row" class="container-title pt-3 mb-2">
    <span data-testid="meterCostAndBound-dialog-title">
      {{ data?.mode | titlecase }}
      {{ 'Scenario.dialog.meters.labels.meterCostAndBound.title' | translate }}
    </span>
    <prosumer-tooltip-anchor
      [message]="'wizard_meters.wizard_meters_meter_cost_and_bounds'"
      data-testid="meterCostAndBound-dialog-title-tooltip"
    ></prosumer-tooltip-anchor>
  </h2>

  <mat-dialog-content class="container-content mb-4">
    <div class="formgrid grid">
      <div class="field col-12 mb-0">
        <prosumer-select
          [required]="true"
          [options]="meterDefinitionDt()"
          [control]="meterCostAndBoundForm?.controls?.meterId"
          [label]="
            'Scenario.dialog.meters.labels.meterCostAndBound.meterId'
              | translate
          "
          [placeholder]="
            'Scenario.dialog.meters.placeholders.meterCostAndBound.meterId'
              | translate
          "
          [tooltip]="
            'wizard_meters.wizard_meters_meter_cost_and_bounds_meter_definition'
          "
          [errorMessageMap]="errorMessages.meterId"
          data-testid="meterCostAndBound-dialog-form-meterId"
        >
        </prosumer-select>
      </div>
      <div class="field col-12 mb-0">
        @if (scenarioVariationsDt()) {
          <prosumer-select
            [placeholder]="
              'Scenario.dialog.meters.placeholders.meterCostAndBound.scenarioVariation'
                | translate
            "
            [label]="
              'Scenario.dialog.meters.labels.meterCostAndBound.scenarioVariation'
                | translate
            "
            [options]="scenarioVariationsDt()"
            [control]="meterCostAndBoundForm?.controls?.scenarioVariation"
            [errorMessageMap]="errorMessages.scenarioVariation"
            data-testid="meterCostAndBound-dialog-form-scenarioVariation"
          ></prosumer-select>
        }
      </div>
    </div>

    <div class="field">
      <prosumer-time-blocks-interval
        [columnDef]="columnDef"
        [formControl]="meterCostAndBoundForm?.get('profiles')"
        [startYearPeriod]="activePeriod()[0]"
        [endYearPeriod]="activePeriod()[1]"
        [isViewOnly]="isViewOnly()"
        [binaryLocation]="profilesDataLocation"
        [isLoading]="isTPLoading()"
        [emptyDataMesssage]="
          'Scenario.dialog.meters.labels.meterCostAndBound.emptyUniqueTimeBlocksList'
            | translate
        "
        data-testid="meterCostAndBound-dialog-form-timeBlocks"
      ></prosumer-time-blocks-interval>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row" class="gap-2 pb-5">
    @if (!data.isViewOnly) {
      <prosumer-entity-upsert-button
        [id]="data?.entity?.id"
        [mode]="data?.mode"
        [data]="meterCostAndBoundForm?.value"
        [valid]="meterCostAndBoundForm?.valid"
        [disabled]="meterCostAndBoundForm?.pristine"
        (ok)="onClose()"
        (attempt)="onSaveAttempt()"
      ></prosumer-entity-upsert-button>
    }
    <button
      mat-flat-button
      type="button"
      [disabled]="isLoading() || onEditLoading()"
      (click)="onClose()"
      data-testid="meterCostAndBound-dialog-form-btn-cancel"
    >
      {{ 'Generic.labels.close' | translate }}
    </button>
  </mat-dialog-actions>
</form>
